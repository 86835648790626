<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            View Report
        </h1>
        <div v-if="!loading && report" class="flex flex-col items-center">
            <div class="w-full max-w-full my-2 sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="border-b border-gray-200 shadow-lg overflow-hidden sm:rounded-lg">
                        <table class="divide-gray-200 overflow-x-auto min-w-full divide-">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th class="text-gray-500 px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Product(s)</th>
                                    <th class="text-gray-500 px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">  Monthly Revenue</th>
                                    <th class="text-gray-500 px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">  Annual Revenue</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-gray-200 divide-y">
                                <tr v-for="(product_data, product_id) in contents['products']" :key="product_id">
                                    <td class="px-6 py-3 text-left">
                                        <div v-for="(id,i) in product_id.split('-')"  :key="i" class="mx-2">
                                            {{products[id].name}}
                                        </div>
                                    </td>
                                    <td class="px-6 py-3 text-left">
                                        {{formatter.format(product_data.monthly)}}
                                    </td>
                                    <td class="px-6 py-3 text-left">
                                        {{formatter.format(product_data.annual)}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-6 py-3 text-left">
                                        <div class="mx-2">
                                            <strong>Totals</strong>
                                        </div>
                                    </td>
                                    <td class="px-6 py-3 text-left">
                                        <strong>{{formatter.format(contents.monthly.total)}}</strong>
                                    </td>
                                    <td class="px-6 py-3 text-left">
                                        <strong>{{formatter.format(contents.annual.total)}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="flex flex-col items-center">
            <img class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading Report">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ViewReport',
    data: () => ({
        loading: false,
        report: false,
        contents: false,
        products: false,
        formatter: false,
        columns: [
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        GetReport() {
            this.loading = true
            this.$requests.get(`/reports/${this.$route.params.report_id}`,
            {
                success: (re) => {
                    this.loading = false
                    this.report = re.data.report
                    this.contents = re.data.contents
                    this.products = re.data.products
                    return true
                },
            })
        }
    },
    watch: {
    },
    created() {
        this.formatter = new Intl.NumberFormat('en-US',{
            style: 'currency',
            currency: 'USD',
        })
        this.GetReport()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
