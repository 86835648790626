<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Reports
        </h1>
        <LinkTemplate :to="{path:'/reports/create'}" class="my-6 ml-6">
            Add New Report
        </LinkTemplate>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/reports/" :columns="columns" :items="reports"></TableTemplate>
    <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
</div>
</template>

<script>

export default {
    name: 'ProductList',
    data: () => ({
        search: '',
        search_timeout: false,
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'user_id',
                label:'User ID',
                action: 'GotToUser',
                action_type: 'link',
            },
            {
                id: 'report_type',
                label: 'Type',
            },
            {
                id:'name',
                label:'Name',
            },
            {
                id:'created_at',
                label:'Date Created',
            },
        ],
        reports: [],
        loading: false,
    }),
    computed: {
    },
    props: [],
    methods: {
        doSearch() {

            this.loading = true
            this.$requests.post(`/search`,{
                search: this.search,
                params: {
                    'model': 'Report',
                    'fields': ['name','user_id','id','report_type','created_at'],
                },
            },
            {
                success: (re) => {
                    this.loading = false
                    this.reports = re.data.items
                    return true
                },
            })
        },
        maybeSearch() {
            if(this.search_timeout) clearTimeout(this.search_timeout);
            let that = this;
            if(!that.search || !that.search.length){
                that.getReports();
                return;
            }
            this.search_timeout = setTimeout(function(){ that.doSearch() }, 1000);
        },
        ItemDeleted(item) {
            console.log('deleted: ',item)
        },
        getReports() {
            this.loading = true
            this.$requests.get(`/reports/getReports`,
            {
                success: (re) => {
                    this.loading = false
                    this.reports = re.data.items
                    return true
                },
            })
        },
        GotToUser(item) {
            this.$router.push(`/users/${item.user_id}`)
        },
    },
    watch: {
    },
    created() {
        this.getReports()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
