<template>
<div class="container pt-16 px-2 ">

    <h1>Create A Report</h1>
    <div class="pt-6" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/reports/create" :formdata="formData">
                <TextInput type="text" label="Name" v-model="report.name" :required="true" ></TextInput>
                <SelectInput label="Type" v-model="report.report_type" :options="[{label:'Revenue',value:'Revenue'}]" :required="true" ></SelectInput>
            </FormTemplate>
    </div>
</div>
</template>

<script>
export default {
    name: 'CreateReport',
    data: () => ({  
        loading:false,
        report: {
            name: '',
            report_type: 'Revenue',
        },
    }),
    computed: {
        formData() {
            return {
                'report':this.report,
            }
        },
    },
    props: [],
    methods: { 
        formResponse(re) {
            if(re.data.status == 'OK') {
                this.$notify({
                    title: 'Report Queued',
                    text: 'This could take a while. You will be notified when the report has been generated.',
                    type: 'success',
                });
                this.$router.push({path:'/reports'})
            }
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
        
    },
    components: {
    },
}
</script>
<style scoped>
</style>
